import {Http} from '@wix/wixstores-client-core/dist/es/src/http/http';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {StoreMetaDataService} from './StoreMetaDataService';
import {
  ICanCheckoutResponse,
  ICheckoutOutOfViewerQueryParams,
  ICheckoutQueryParams,
  IProductDTO,
  IPropsInjectedByViewerScript,
  IStoreMetaData,
} from '../types/app-types';
import {ModalManager} from '@wix/wixstores-client-core/dist/es/src/modalManager/modalManager';
import {baseModalUrl, ModalState, ModalTheme, ModalType, Origin} from '../constants';
import {SPECS} from '../specs';
import {IStoreInfo} from '@wix/wixstores-graphql-schema';
import {LostBusinessNotifier} from './LostBusinessNotifier';
import _ from 'lodash';
import {isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';

export const CHECKOUT_URL = '/_api/wix-ecommerce-renderer-web/store-front/checkout/cart/{cartId}';

export interface CheckoutInfo {
  cartId: string;
  isFastFlow: boolean;
  checkoutRelativeUrl: string;
  siteBaseUrl: string;
  thankYouPageUrl: string;
  cartUrl: string;
  paymentMethodName: string;
  locale: string;
  deviceType: string;
  a11y: boolean;
  originType: string;
  cashierPaymentId?: string;
}

export class CheckoutService {
  private readonly siteStore: SiteStore;
  private readonly httpClient: Http;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly modalManger: ModalManager;
  private readonly lostBusinessNotifier: LostBusinessNotifier;
  private storeMetaData: IStoreMetaData;

  constructor(
    siteStore: SiteStore,
    httpClient: Http,
    private readonly nextProps: (additionalProps: Partial<IPropsInjectedByViewerScript>) => void
  ) {
    this.siteStore = siteStore;
    this.httpClient = httpClient;
    this.storeMetaDataService = new StoreMetaDataService(this.siteStore);
    const openModal = async (url, width, height) => {
      return this.siteStore.windowApis.openModal(url, {width, height, theme: ModalTheme.BARE});
    };
    this.modalManger = new ModalManager({openModal}, baseModalUrl, this.siteStore.instanceManager.getInstance());
    this.lostBusinessNotifier = new LostBusinessNotifier(this.siteStore.httpClient);
  }

  private readonly navigateToCheckoutOutViewer = async (checkoutInfo: CheckoutInfo) => {
    const queryParams: ICheckoutOutOfViewerQueryParams = {
      paymentMethodName: checkoutInfo.paymentMethodName,
      locale: checkoutInfo.locale,
      cartId: checkoutInfo.cartId,
      successUrl: checkoutInfo.thankYouPageUrl,
      cancelUrl: checkoutInfo.cartUrl,
      siteBaseUrl: checkoutInfo.siteBaseUrl,
      isFastFlow: checkoutInfo.isFastFlow,
      isPickupFlow: this.storeMetaData.shipping.isPickupOnly,
      origin: 'productPage',
      originType: checkoutInfo.originType,
    };

    const checkoutUrl = await this.getCheckoutOutOfViewerUrl(
      checkoutInfo.deviceType,
      checkoutInfo.a11y,
      queryParams,
      {
        cartId: checkoutInfo.cartId,
      },
      checkoutInfo.cashierPaymentId
    );

    if (isWorker()) {
      this.siteStore.location.to(checkoutUrl);
    } else {
      window.open(checkoutUrl, '_top');
    }
  };

  private readonly getCheckoutOutOfViewerUrl = async (
    deviceType: string,
    a11y: boolean,
    queryParams: ICheckoutOutOfViewerQueryParams,
    templateParams: {cartId: string},
    cashierPaymentId?: string
  ): Promise<string> => {
    const serverResult = (await this.httpClient.get(CHECKOUT_URL, {templateParams, queryParams})) as any;
    let checkoutUrl = `${serverResult.url}&deviceType=${deviceType}&a11y=${a11y}`;
    if (cashierPaymentId) {
      checkoutUrl += `&cashierPaymentId=${cashierPaymentId}`;
    }
    const policy = this.siteStore.usersApi.getCurrentConsentPolicy();
    if (!policy.defaultPolicy) {
      checkoutUrl += `&consent-policy=${this.siteStore.usersApi._getConsentPolicyHeader()['consent-policy']}`;
    }
    return checkoutUrl;
  };

  private readonly isEligibleForCheckoutInViewer = async (): Promise<boolean> => {
    const isCheckoutInstalled = await this.siteStore.siteApis.isAppSectionInstalled({
      appDefinitionId: APP_DEFINITION_ID,
      sectionId: PageMap.CHECKOUT,
    });

    const url = this.siteStore.location.url;
    const isSslSecured = _.startsWith(url, 'https');
    return isCheckoutInstalled && isSslSecured;
  };

  private readonly sendLostBusinessEmail = (storeInfo: IStoreInfo) => {
    storeInfo.isPremium && this.lostBusinessNotifier.notify();
  };

  public openModalByType = async (modalType: ModalType): Promise<void> => {
    const biParams = {origin: Origin.PRODUCT_PAGEֹֹ_CHECKOUT, mode: 'editor', isMerchant: true};

    switch (modalType) {
      case ModalType.SetShipping: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.showShippingPopupSf({
          type: 'merchant pop-up',
          ...biParams,
        });
        return this.modalManger.openSetShippingMethod();
      }

      case ModalType.SetPayment: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.showMerchantPaymentPopupSf(biParams);
        return this.modalManger.openSetPaymentMethod();
      }
      case ModalType.UpgradeToPremium: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.showMerchantUpgradePopupSf(biParams);
        await this.modalManger.openUpgradeToPremium();
        break;
      }
      case ModalType.NotInLiveSite: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.viewCheckoutInLiveSitePopupSf(biParams);
        return this.modalManger.openNotInLiveSite();
      }
      case ModalType.Subscriptions: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.notAcceptPaymentsVisitorPopupSf({origin: Origin.PRODUCT_PAGEֹֹ_CHECKOUT});
        this.nextProps({modalState: ModalState.OPEN});
        return this.modalManger.openSubscriptions().then(() => {
          this.nextProps({modalState: ModalState.CLOSE});
        });
      }
      case ModalType.HighArpuSubscriptions: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.subscriptionsAreComingSoonVisitorPopupSf({origin: Origin.PRODUCT_PAGEֹֹ_CHECKOUT});
        this.nextProps({modalState: ModalState.OPEN});
        return this.modalManger.openUpgradeSubscriptions().then(() => {
          this.nextProps({modalState: ModalState.CLOSE});
        });
      }
      case ModalType.NoOnlinePayments: {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.siteStore.biLogger.notAcceptPaymentsVisitorPopupSf({origin: Origin.PRODUCT_PAGEֹֹ_CHECKOUT});
        this.nextProps({modalState: ModalState.OPEN});
        return this.modalManger.openNoOnlinePayments().then(() => {
          this.nextProps({modalState: ModalState.CLOSE});
        });
      }
    }
  };

  private readonly isSubscriptionsPremiumFeature = (): boolean => {
    return (
      _.findIndex(this.siteStore.premiumFeatures, (feature) => {
        return feature.name === 'stores_subscriptions';
      }) !== -1
    );
  };

  public checkIsAllowedToCheckout = async (
    product: IProductDTO,
    isSubscribe = false
  ): Promise<ICanCheckoutResponse> => {
    this.storeMetaData = await this.storeMetaDataService.fetchStoreInfo().catch((e) => {
      throw e;
    });

    const canStoreShip = product.productType === 'digital' || this.storeMetaData.storeInfo.canStoreShip;

    if (this.siteStore.isPreviewMode() || this.siteStore.isEditorMode()) {
      if (!canStoreShip) {
        return {modalType: ModalType.SetShipping, canCheckout: false};
      } else if (!this.storeMetaData.storeInfo.hasCreatedPaymentMethods) {
        return {modalType: ModalType.SetPayment, canCheckout: false};
      } else if (!this.storeMetaData.storeInfo.isPremium) {
        return {modalType: ModalType.UpgradeToPremium, canCheckout: false};
      } else if (isSubscribe && !this.isSubscriptionsPremiumFeature()) {
        return {modalType: ModalType.HighArpuSubscriptions, canCheckout: false};
      } else {
        return {modalType: ModalType.NotInLiveSite, canCheckout: false};
      }
    } else if (
      !this.storeMetaData.storeInfo.isPremium ||
      !this.storeMetaData.storeInfo.hasCreatedPaymentMethods ||
      !canStoreShip
    ) {
      this.siteStore.experiments.enabled(SPECS.ENABLE_LOST_BUSINESS_EMAIL) &&
        this.sendLostBusinessEmail(this.storeMetaData.storeInfo);
      return {modalType: ModalType.NoOnlinePayments, canCheckout: false};
    } else if (isSubscribe && !this.isSubscriptionsPremiumFeature()) {
      return {modalType: ModalType.Subscriptions, canCheckout: false};
    }

    return {modalType: undefined, canCheckout: true};
  };

  public navigateToCheckout = async (checkoutInfo: CheckoutInfo): Promise<void> => {
    if (await this.isEligibleForCheckoutInViewer()) {
      const queryParams: ICheckoutQueryParams = {
        a11y: checkoutInfo.a11y,
        cartId: checkoutInfo.cartId,
        storeUrl: checkoutInfo.siteBaseUrl, //this param is not used in client or server but needed by cashier! dont remove it!
        isFastFlow: checkoutInfo.isFastFlow,
        isPickupFlow: this.storeMetaData.shipping.isPickupOnly,
        cashierPaymentId: checkoutInfo.cashierPaymentId || '',
        origin: 'productPage',
        originType: checkoutInfo.originType,
      };

      await this.siteStore.navigate(
        {
          sectionId: PageMap.CHECKOUT,
          queryParams,
        },
        true,
        true
      );
    } else {
      await this.navigateToCheckoutOutViewer(checkoutInfo);
    }
  };
}
