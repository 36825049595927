import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {IControllerConfig} from '@wix/wixstores-client-core/dist/src/types/native-types';

export const enum AddToCartState {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export class AddToCartService {
  private readonly allowFreeProducts: boolean;

  constructor(siteStore: SiteStore, publicData: IControllerConfig['publicData']) {
    const withExperiment = siteStore.experiments.enabled('specs.stores.AddFreeProductsToCart');
    const isLegacy = publicData.APP?.ALLOW_FREE_PRODUCTS === false;
    this.allowFreeProducts = withExperiment && !isLegacy;
  }

  public getButtonState(price: number, inStock: boolean): AddToCartState {
    if (!inStock) {
      return AddToCartState.OUT_OF_STOCK;
    }
    if (price === 0 && this.allowFreeProducts === false) {
      return AddToCartState.DISABLED;
    }
    if (price < 0) {
      return AddToCartState.DISABLED;
    }
    return AddToCartState.ENABLED;
  }
}
